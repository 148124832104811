import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Call DIS-POOP-JOB which is (347) 766-7562 or `}<a parentName="p" {...{
        "href": "mailto:owner@muttmess.management?subject=New%20Customer&body=Hello,%20I%20am%20interested%20in%20becoming%20a%20customer!"
      }}>{`email me`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      